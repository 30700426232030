import React, { FC } from "react";
import ChevronDown from "../../assets/heroicons/small/chevron-down.svg";
import { RichText } from "prismic-reactjs";
import { CustomPrismicLink } from "../../components/CustomPrismicLink/CustomPrismicLink";
import { PageSection } from "../PageSection/PageSection";
import styles from "./FAQ.module.scss";
import { FAQ as FAQType } from "../../types/types";

type FAQsType = {
	faqs: FAQType[];
};

export const FAQs: FC<FAQsType> = ({ faqs }) => {
	return (
		<PageSection id="faqs" heading="FAQs">
			<div className={styles.faqWrapper}>
				{faqs.map((item) => {
					return <FAQ key={item.id} {...item} />;
				})}
			</div>
		</PageSection>
	);
};

export const FAQ: FC<FAQType> = (props) => {
	const {
		question,
		answer: { richText },
	} = props.data;

	return (
		<details className={styles.faq}>
			<summary className={styles.summary}>
				{/* need this div because https://stackoverflow.com/questions/65301531/summary-with-flexbox-and-pseudo-element-fails-to-show-inline-in-safari */}
				<div className={styles.summaryInner}>
					<span>{question}</span>
					<ChevronDown
						className={styles.icon}
						aria-hidden={true}
						focusable="false"
					/>
				</div>
			</summary>
			<div className={styles.faqContent}>
				<RichText render={richText} serializeHyperlink={CustomPrismicLink} />
			</div>
		</details>
	);
};
