import React, { FC, ReactElement } from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/Layout/Layout";
import { PageHeader } from "../components/PageHeader/PageHeader";
import { FAQ as FAQType } from "../types/types";
import { SEO } from "../components/SEO/SEO";
import { Container } from "../components/Container/Container";
import { Pricing } from "../components/Pricing/Pricing";
import { Callout } from "../components/Callout/Callout";
import { FAQs } from "../components/FAQ/FAQ";

type PricingPageProps = PageProps<{
	allPrismicFaq: {
		nodes: FAQType[];
	};
}>;

const PricingPage: FC<PricingPageProps> = ({
	data: {
		allPrismicFaq: { nodes: faqs },
	},
}): ReactElement => {
	return (
		<Layout>
			<SEO title="Pricing" description="Compare plans" />
			<PageHeader
				heading="Get started for free"
				lead="Whether you’re an individual, school or business, Adeptly is the game builder for everyone. Upgrade and downgrade your plan when you want worry free."
			/>
			<Container>
				<Pricing />
				{faqs?.length > 0 && <FAQs faqs={faqs} />}
				<Callout />
			</Container>
		</Layout>
	);
};

export default PricingPage;

export const pageQuery = graphql`
	{
		allPrismicFaq(filter: { data: { pricing: { eq: true } } }) {
			nodes {
				...FAQ
			}
		}
	}
`;
