import { DocumentType } from "./document-types";

/**
 * Custom document type to allow this to work for custom links (see CustomPrismicLink)
 */
export interface LinkDoc {
	type: string;
	uid: string;
	id: string;
	link_type: "Document" | "Web";
	url?: string;
	target?: string;
}

/**
 * A link resolver in Prismic is a function that takes a document and returns a
 * relative URL to that document.
 * See https://prismic.io/docs/technologies/link-resolver-javascript
 */
export const linkResolver = (doc: LinkDoc): string => {
	switch (doc.type) {
		case DocumentType.Homepage:
		case DocumentType.Layout:
			return "/";
		case DocumentType.ResourceCentre:
			return `/resource-centre/`;
		case DocumentType.Post:
			return `/resource-centre/${doc.uid}`;
		default:
			return `/${doc.type}`;
	}
};
