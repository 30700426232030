import uniq from "lodash.uniq";

export interface FeatureItem {
	section: string;
	feature: string;
	hideFeatureTitle: boolean | null;
	featureTooltip: string | null;
	free: string | number | null;
	individual: string | number | null;
	enterprise: string | number | null;
	// team: string | number | null;
}

export type FeatureObjectType = {
	[key: string]: FeatureItem[];
};

export function featureSorter(features: FeatureItem[]): FeatureObjectType {
	// unique sections
	// const sections = [...new Set(features.map((d) => d.section))];
	const sections = uniq(features.map((d) => d.section));
	// set up objects
	const final: FeatureObjectType = {};
	// get the features that match the section name
	sections.map((section) => {
		return (final[section] = features.filter(
			(feature) => feature.section === section
		));
	});
	return final;
}
