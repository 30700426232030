import React, { FC } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { PageSection } from "./../../PageSection/PageSection";
import { featureSorter, FeatureItem } from "./feature-sorter";
import CheckIcon from "../../../assets/heroicons/small/check.svg";
import PlusIcon from "../../../assets/heroicons/small/plus.svg";
import styles from "./PlanComparison.module.scss";
import planFeatures from "./planFeatures.json";

export const PlanComparison: FC = () => {
	const features = featureSorter(planFeatures);

	return (
		<div className={styles.planComparison}>
			<PageSection id="compare-plans" heading="Compare plans">
				<table className={styles.table}>
					<thead>
						<tr>
							<th scope="col">
								<span className="visuallyHidden">Feature</span>{" "}
							</th>
							<th className={styles.fixedWidth} scope="col">
								<span className={styles.colHeading}>Free</span>
								<a href="https://my.adeptly.ai/">Get started for free</a>
							</th>
							<th className={styles.fixedWidth} scope="col">
								<span className={styles.colHeading}>Individual</span>
								<a href="https://my.adeptly.ai/">Get started for free</a>
							</th>
							<th className={styles.fixedWidth} scope="col">
								<span className={styles.colHeading}>Enterprise</span>
								<a href="https://my.adeptly.ai/">Get started for free</a>
							</th>
							{/* <th className={styles.fixedWidth} scope="col">
								<span className={styles.colHeading}>Team</span>
								<a href="https://my.adeptly.ai/">Get started for free</a>
							</th> */}
						</tr>
					</thead>

					{Object.keys(features).map((feature) => (
						<FeatureTableSection
							key={feature}
							section={feature}
							features={features[feature]}
						/>
					))}
				</table>
			</PageSection>
		</div>
	);
};

interface TableFeatureSectionProps {
	section: string;
	features: FeatureItem[];
}

const FeatureTableSection: FC<TableFeatureSectionProps> = ({
	section,
	features,
}) => {
	return (
		<>
			<tbody>
				<tr>
					<th scope="row" colSpan={5} className={styles.tableSection}>
						<span className={styles.tableSectionHeading}>{section}</span>
					</th>
				</tr>
			</tbody>

			<tbody>
				{features.map(
					({
						feature,
						hideFeatureTitle = false,
						featureTooltip,
						free,
						individual,
						enterprise,
					}) => {
						const levels = [free, individual, enterprise];

						return (
							<tr key={feature}>
								<td className={styles.baseCell}>
									<span
										className={classnames({ visuallyHidden: hideFeatureTitle })}
									>
										{feature}
										{featureTooltip && (
											<>
												<br />
												<small>{featureTooltip}</small>
											</>
										)}
									</span>
								</td>
								{levels.map((level, index) => (
									<td
										key={level + String(index)}
										className={classnames([styles.baseCell, styles.dataCell])}
									>
										{/* If there is something in this cell */}
										{level ? (
											// if the item is just a cross, indicating "included" for this cell, render the tick...
											level === "x" ? (
												<>
													<CheckIcon
														className={classnames([styles.icon, styles.check])}
													/>
													<span className="visuallyHidden">Included</span>
												</>
											) : (
												// ...otherwise just render what it says
												addLineBreaks(level)
											)
										) : (
											// If there's nothing in the cell then presume it's not included - render a cross
											<>
												<PlusIcon
													className={classnames([styles.icon, styles.cross])}
												/>
												<span className="visuallyHidden">Not included</span>
											</>
										)}
									</td>
								))}
							</tr>
						);
					}
				)}
			</tbody>
		</>
	);
};

function addLineBreaks(input: string | number) {
	if (typeof input === "number" || input.indexOf("\n") === -1) {
		return input;
	} else {
		return input.split("\n").map((bit) => (
			<>
				<span key={bit}>{bit}</span>
				<br />
			</>
		));
	}
}
