import React, { FC, useState } from "react";
import classnames from "classnames";
import { Button } from "../../Button/Button";
import BulletIcon from "../../../assets/heroicons/small/bullet.svg";
import ChevronDown from "../../../assets/heroicons/small/chevron-down.svg";
import styles from "./PriceCalculator.module.scss";

export interface PricingPlan {
	name: string;
	level: number;
	tag: string | null;
	cta: string;
	editors?: ActiveEditorType[];
	pricing?: PricingType;
	headlines: string[];
	//players: number | null;
	features: string[];
}

export interface PriceCalculatorProps {
	plan: PricingPlan;
	viewPricingMonthly: boolean;
	hasTag: string | null;
}

export type PricingType = {
	monthly: number | string;
	annually: number;
	unit: string;
	ctaUrl: string;
};

export type ActiveEditorType = {
	qty: number;
	price: PricingType;
};

export const PriceCalculator: FC<PriceCalculatorProps> = ({
	plan: { cta, pricing, name, editors, headlines },
	hasTag,
	viewPricingMonthly,
}) => {
	const [tier, setTier] = useState(0);

	const currentDetails = editors ? editors[tier].price : pricing || null;

	// console.log(currentDetails);

	return (
		<>
			<p className={styles.priceWrapper}>
				{currentDetails ? (
					<>
						<span
							className={classnames({
								[styles.price]: true,
								[styles.tagPrice]: hasTag,
							})}
						>
							{viewPricingMonthly ? (
								currentDetails.monthly
							) : (
								<span aria-describedby="annual-pricing-note">
									{currentDetails.annually}
								</span>
							)}
							<span className="visuallyHidden">pounds</span>
						</span>
						{!viewPricingMonthly && name !== "Free" && (
							<>
								<span className={styles.discountPrice}>
									{currentDetails.monthly}{" "}
									<span className="visuallyHidden">
										pounds - equivalent price when paid monthly
									</span>
								</span>
							</>
						)}
						{pricing?.monthly !== 0 && pricing?.monthly !== null && (
							<span className={styles.perMonth}></span>
						)}
					</>
				) : (
					<span className={styles.price}>&pound;call</span>
				)}

				{editors ? (
					<div className={styles.activeGameControl}>
						{renderSelector(editors, setTier, name, !!hasTag)}
					</div>
				) : (
					<span>{currentDetails?.unit}</span>
				)}
			</p>
			{currentDetails?.ctaUrl === "form" ? (
				<>
					<div data-tf-live="01HVK2HVAFGC2FCBDJYE4H5N3Z"></div>
					<script src="//embed.typeform.com/next/embed.js"></script>
				</>
			) : (
				<Button
					inverse={!!hasTag}
					className={styles.cta}
					variant="small"
					to={currentDetails?.ctaUrl}
				>
					{cta}
				</Button>
			)}
			{/* <ul className={classnames(styles.headlines)}>
				{headlines.map((headline) => (
					<li key={headline}>
						<BulletIcon
							className={classnames(styles.icon, styles.iconAdditional)}
							focusable="false"
							aria-hidden="true"
						/>{" "}
						{headline}
					</li>
				))}
			</ul> */}
		</>
	);
};

function renderSelector(
	editors: ActiveEditorType[],
	setTier: (index: number) => void,
	uniqueId: string,
	hasTag: boolean
) {
	function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
		setTier(Number(event.target.value));
	}

	switch (editors.length) {
		case 0:
			throw Error("Zero editors doesn't make sense");
		// Presuming that no games means unlimited
		//return <span>Unlimited editors</span>;
		case 1:
			return <span>1 editor</span>;
		default:
			// Else return with a control to choose the tier
			return (
				<>
					<label htmlFor={`choice-${uniqueId}`} className="visuallyHidden">
						Number of game editors
					</label>
					<select
						className={classnames({
							[styles.tierSelect]: true,
							[styles.hasTag]: hasTag,
						})}
						id={`choice-${uniqueId}`}
						onChange={handleChange}
					>
						{editors.map(({ qty }, index) => (
							<option value={index} key={index}>
								{qty} {qty > 1 ? "editors" : "editor"}
							</option>
						))}
					</select>
					<ChevronDown
						aria-hidden="true"
						className={classnames({
							[styles.selectChevron]: true,
							[styles.hasTag]: hasTag,
						})}
					/>
				</>
			);
	}
}
