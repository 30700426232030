import React, { FC } from "react";
import classNames from "classnames";
import {
	ActiveEditorType,
	PriceCalculator,
	PricingPlan,
	PricingType,
} from "../PriceCalculator/PriceCalculator";
import styles from "./PricingLevel.module.scss";
import TickIcon from "../../../assets/heroicons/small/check.svg";
import PlusIcon from "../../../assets/heroicons/small/plus.svg";
import ArrowLeftIcon from "../../../assets/heroicons/arrow-left.svg";

export interface PricingLevelProps {
	plans: PricingPlan[];
	currentIndex: number;
	viewPricingMonthly: boolean;
}

export const PricingLevel: FC<PricingLevelProps> = ({
	plans,
	currentIndex,
	viewPricingMonthly,
}) => {
	// Passing all the plans at once to allow subsequent plans to reference prior
	const currentPlan = plans[currentIndex];
	const { name, level, tag, features } = currentPlan;
	return (
		<div
			className={classNames(styles.pricing, {
				[styles.hasTag]: !!tag,
			})}
		>
			<div className={styles.header}>
				{tag && <span className={styles.tag}>{tag}</span>}

				<h2 className={styles.heading}>{name}</h2>

				<PriceCalculator
					plan={currentPlan}
					hasTag={tag}
					viewPricingMonthly={viewPricingMonthly}
				/>
			</div>

			<div className={styles.body}>
				<ul aria-label="What's included" className={styles.list}>
					{level > 0 && (
						<li className={styles.featuresContinued}>
							<ArrowLeftIcon
								className={classNames(styles.icon)}
								focusable="false"
								aria-hidden="true"
							/>{" "}
							Everything in &quot;{plans[level - 1].name}&quot;&hellip;
						</li>
					)}

					{features.map((feature: string) => (
						<li key={feature}>
							{level === 0 ? (
								<TickIcon
									className={styles.icon}
									focusable="false"
									aria-hidden="true"
								/>
							) : (
								<PlusIcon
									className={classNames(styles.icon)}
									focusable="false"
									aria-hidden="true"
								/>
							)}{" "}
							{feature}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
