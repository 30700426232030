import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Elements, HTMLSerializer } from "prismic-reactjs";

import { linkResolver, LinkDoc } from "../../cms/link-resolver";

export const CustomPrismicLink: HTMLSerializer<ReactNode> = (
	_type: Elements,
	element: { data: LinkDoc },
	content: string
) => {
	// Todo: there's probably a third type of link to files which we might need to support
	if (element.data.link_type === "Document") {
		return (
			<Link to={linkResolver(element.data)} key={element.data.id}>
				{content}
			</Link>
		);
	}

	if (element.data.link_type === "Web") {
		return (
			<a
				href={element.data.url}
				target={element.data.target}
				rel={
					element.data.target === "_blank" ? "noopener noreferrer" : undefined
				}
			>
				{content}
			</a>
		);
	}
	return null;
};
