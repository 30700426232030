import React, { useState, FC } from "react";
import Switch from "react-switch";
import { PricingLevel } from "./PricingLevel/PricingLevel";
import { PlanComparison } from "./PlanComparison/PlanComparison";
import styles from "./Pricing.module.scss";
import plans from "./plans.json";

export const Pricing: FC = () => {
	const [viewPricingMonthly, setViewPricingMonthly] = useState(true);

	return (
		<>
			{/* <label className={styles.switchWrapper} htmlFor="switch">
				<span aria-hidden="true">Yearly</span>
				<Switch
					id="switch"
					activeBoxShadow="0px 0px 0px 5px #fd0"
					className={styles.switch}
					aria-label="Activate to show monthly pricing"
					uncheckedIcon={false}
					checkedIcon={false}
					onColor="#1b68ff"
					offColor="#1b68ff"
					height={38}
					width={63}
					handleDiameter={30}
					onChange={(change) => {
						setViewPricingMonthly(change);
					}}
					checked={viewPricingMonthly}
				/>
				<span aria-hidden="true">Monthly</span>
			</label> */}

			<ol className={styles.pricingList}>
				{plans.map((_, i) => (
					<li key={i}>
						<PricingLevel
							plans={plans}
							currentIndex={i}
							viewPricingMonthly={viewPricingMonthly}
						/>
					</li>
				))}
			</ol>
			{!viewPricingMonthly && (
				<p id="annual-pricing-note" className={styles.pricingFootnote}>
					<span aria-hidden="true">*</span> Prices based on annual subscription
				</p>
			)}

			<PlanComparison />
		</>
	);
};
